
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexMVRyVltcZU9cSOHvXFm673ZzZMLZS5hwHGVbkmFSbV8Meta } from "/usr/src/nuxt3-app/pages/index.vue?macro=true";
import { default as loginijOx7mgVFAiUutJ0Nnagm0TDwhfP9yBisX3PE9xK65cMeta } from "/usr/src/nuxt3-app/pages/login.vue?macro=true";
import { default as notesafv4ti_fjBtug_45_tmuU0twIBeX5Rs6abe2CrcRLEImkMeta } from "/usr/src/nuxt3-app/pages/notes.vue?macro=true";
import { default as scoutnpdSRcMg78Of_45KbTKEKcWOrMDSn4NGisJUlTM8mwa08Meta } from "/usr/src/nuxt3-app/pages/scout.vue?macro=true";
import { default as users8mbLJq4ncL5atm_45_cV0Lvr71QXdXIPZUQAMMkqLpE9gMeta } from "/usr/src/nuxt3-app/pages/users.vue?macro=true";
import { default as matchesklvxOetMNwhNtEUFnoabwqlkGmSyzdDK8JKwxLJXTJIMeta } from "/usr/src/nuxt3-app/pages/matches.vue?macro=true";
import { default as predictgIlBZhMjKoNuWbmcwd_45NkZ_33CueozALUpPCk7_giTsMeta } from "/usr/src/nuxt3-app/pages/predict.vue?macro=true";
import { default as settingsJFJzlZCUUdrI4zWsg_KYx1AR2KO6TzW1gy95o0z2XAcMeta } from "/usr/src/nuxt3-app/pages/settings.vue?macro=true";
import { default as dashboardgt8Bidby3dDpXNslSKsrGKC99fyD3PQeZVNtrQPl2xQMeta } from "/usr/src/nuxt3-app/pages/dashboard.vue?macro=true";
import { default as _91id_93dYFHxF9157pBcOqsd0P95IWZMfX_45k4Al_45Jd_F48R_fMMeta } from "/usr/src/nuxt3-app/pages/teams/[id].vue?macro=true";
import { default as indexmIgMk6e4F9w6_K8QzVsON7Y_45ps4qtC7OxjipWQvQ9S4Meta } from "/usr/src/nuxt3-app/pages/teams/index.vue?macro=true";
import { default as addKYNJiVrPM5v42oc1WOBvX2pAlApZkZjh8zT8vajBLNoMeta } from "/usr/src/nuxt3-app/pages/attachment/add.vue?macro=true";
import { default as _91id_93eJ2ySeCaRPcWsgzhFgE3EM87mnqP7nwj25wjB_45DvJYsMeta } from "/usr/src/nuxt3-app/pages/attachments/[id].vue?macro=true";
import { default as _91id_933E3pO4QF6Ssk0ILEQGXvTQ5vLjLW_EBOlaNbBSVGd2sMeta } from "/usr/src/nuxt3-app/pages/teams/attachments/[id].vue?macro=true";
import { default as indexG_45Y8p0qq0652UhPR1k5xjd9yYBSKPJ6jjkSgK8Ln_rUMeta } from "/usr/src/nuxt3-app/pages/teams/attachments/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/usr/src/nuxt3-app/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/usr/src/nuxt3-app/pages/login.vue")
  },
  {
    name: "notes",
    path: "/notes",
    component: () => import("/usr/src/nuxt3-app/pages/notes.vue")
  },
  {
    name: "scout",
    path: "/scout",
    component: () => import("/usr/src/nuxt3-app/pages/scout.vue")
  },
  {
    name: "users",
    path: "/users",
    component: () => import("/usr/src/nuxt3-app/pages/users.vue")
  },
  {
    name: "matches",
    path: "/matches",
    component: () => import("/usr/src/nuxt3-app/pages/matches.vue")
  },
  {
    name: "predict",
    path: "/predict",
    component: () => import("/usr/src/nuxt3-app/pages/predict.vue")
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("/usr/src/nuxt3-app/pages/settings.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    component: () => import("/usr/src/nuxt3-app/pages/dashboard.vue")
  },
  {
    name: "teams-id",
    path: "/teams/:id()",
    component: () => import("/usr/src/nuxt3-app/pages/teams/[id].vue")
  },
  {
    name: "teams",
    path: "/teams",
    component: () => import("/usr/src/nuxt3-app/pages/teams/index.vue")
  },
  {
    name: "attachment-add",
    path: "/attachment/add",
    component: () => import("/usr/src/nuxt3-app/pages/attachment/add.vue")
  },
  {
    name: "attachments-id",
    path: "/attachments/:id()",
    component: () => import("/usr/src/nuxt3-app/pages/attachments/[id].vue")
  },
  {
    name: "teams-attachments-id",
    path: "/teams/attachments/:id()",
    component: () => import("/usr/src/nuxt3-app/pages/teams/attachments/[id].vue")
  },
  {
    name: "teams-attachments",
    path: "/teams/attachments",
    component: () => import("/usr/src/nuxt3-app/pages/teams/attachments/index.vue")
  }
]