import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/usr/src/nuxt3-app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/usr/src/nuxt3-app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/usr/src/nuxt3-app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/usr/src/nuxt3-app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/usr/src/nuxt3-app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/usr/src/nuxt3-app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/usr/src/nuxt3-app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/usr/src/nuxt3-app/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/usr/src/nuxt3-app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import slideovers_g1RyjnFCC2XCqS3NLpv7yngCZ5AzjpmT7UJr_dW3BbY from "/usr/src/nuxt3-app/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_JhH8M1KzF3pQyhcHsoNTBLd8tKGet6zo2zTBVDe7nK4 from "/usr/src/nuxt3-app/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_E7kSti5pGZ28QhUUurq6gGRU3l65WuXO_KJC3GQgzFo from "/usr/src/nuxt3-app/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_99tyrJVxV3tJlUbnb2rNb2lrWZJ3yCVaG393DnED9Fg from "/usr/src/nuxt3-app/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_MeUvTuoKUi51yb_kBguab6hdcExVXeTtZtTg9TZZBB8 from "/usr/src/nuxt3-app/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import pwa_icons_plugin_C24GcIKjcI2zsa8A86om0L2LZjx1chWtzYxD11T7Txg from "/usr/src/nuxt3-app/.nuxt/pwa-icons-plugin.ts";
import pwa_client_FQM5r5C96_zrgtYZL3Jbj_skHFBc_M2JlWere1x75dQ from "/usr/src/nuxt3-app/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import vuetify_hjFy4UiBVKu2U8_BW9ggkFzfvErKr3wFgTHpa6TF5Ds from "/usr/src/nuxt3-app/plugins/vuetify.ts";
import pinia_Ab1D_QMMf_egrzVdVYpZ5xs6QSwaKU60pkFvRWVq_9Y from "/usr/src/nuxt3-app/plugins/pinia.ts";
export default [
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  slideovers_g1RyjnFCC2XCqS3NLpv7yngCZ5AzjpmT7UJr_dW3BbY,
  modals_JhH8M1KzF3pQyhcHsoNTBLd8tKGet6zo2zTBVDe7nK4,
  colors_E7kSti5pGZ28QhUUurq6gGRU3l65WuXO_KJC3GQgzFo,
  plugin_client_99tyrJVxV3tJlUbnb2rNb2lrWZJ3yCVaG393DnED9Fg,
  plugin_MeUvTuoKUi51yb_kBguab6hdcExVXeTtZtTg9TZZBB8,
  pwa_icons_plugin_C24GcIKjcI2zsa8A86om0L2LZjx1chWtzYxD11T7Txg,
  pwa_client_FQM5r5C96_zrgtYZL3Jbj_skHFBc_M2JlWere1x75dQ,
  vuetify_hjFy4UiBVKu2U8_BW9ggkFzfvErKr3wFgTHpa6TF5Ds,
  pinia_Ab1D_QMMf_egrzVdVYpZ5xs6QSwaKU60pkFvRWVq_9Y
]